import React from 'react'
import { Box, BoxProps } from '@mui/material'
import $ from './DividersBlock.styled'

export const DividersBlock: Settle.FC<BoxProps> = ({ children, sx, ...props }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      ...sx,
    }}
    {...props}
  >
    <$.Separator side="left" />
    {children}
    <$.Separator side="right" />
  </Box>
)
