import { Box, ButtonProps } from '@mui/material'
import jwt_decode from 'jwt-decode'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbars } from 'settle-ui/hooks/useSnackbars/useSnackbars'

const GOOGLE_BUTTON_WRAP_ID = 'google-button-wrap'

type TCredentialResponse = google.accounts.id.CredentialResponse

export type TGoogleCredential = {
  aud: string
  azp: string
  email: string
  email_verified: boolean
  exp: number
  family_name: string
  given_name: string
  iat: number
  iss: string
  jti: string
  name: string
  nbf: number
  picture: string
  sub: string
}

export interface IGoogleAuthProps {
  sx?: ButtonProps['sx']
  onSuccess(jwt: string, credential: TGoogleCredential): void
  onClick?(): void
}

export const GoogleAuth: React.FC<IGoogleAuthProps> = ({ sx, onSuccess, onClick }) => {
  const { t } = useTranslation()
  const { showError } = useSnackbars()

  const handleCredentialResponse = (response: TCredentialResponse) => {
    try {
      const credential: TGoogleCredential = jwt_decode(response?.credential)

      onSuccess(response?.credential, credential)
    } catch (error) {
      showError(t('somethingWentWrong'))
    }
  }

  useEffect(() => {
    if (!window?.google) {
      return
    }

    window?.google.accounts.id.initialize({
      client_id: process.env.GOOGLE_AUTH_CLIENT_ID,
      callback: handleCredentialResponse,
    })

    window?.google.accounts.id.renderButton(document.getElementById(GOOGLE_BUTTON_WRAP_ID) as HTMLElement, {
      type: 'standard',
      theme: 'outline',
      size: 'large',
      text: 'continue_with',
      shape: 'rectangular',
      logo_alignment: 'left',
    })
  }, [])

  return (
    <Box
      id={GOOGLE_BUTTON_WRAP_ID}
      data-test-id="google-auth-button"
      sx={{ display: 'flex', justifyContent: 'center', width: '100%', height: '40px', ...sx }}
      onClick={onClick}
    />
  )
}
