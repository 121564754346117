import { add } from 'date-fns'
import { setCookie } from 'cookies-next'
import { useCallback } from 'react'
import { getCurrentDomain } from 'lib/helpers/getCurrentDomain/getCurrentDomain'
import { cookieKeys } from 'lib/consts/browserStorage'

type TOptions = Parameters<typeof setCookie>[2]

/**
 * Set the token cookie with the standard expiration date.
 */
export const useSetTokenCookie = () =>
  useCallback((token: string, options?: TOptions) => {
    const o: TOptions = {
      ...options,
      expires: add(new Date(), { years: 1 }),
    }

    // We cannot add the "domain" param in every environment as it will not
    // work on the localhost as it is not a valid host name, and it also will not
    // work on the vercel's preview apps served from the vercel.app domain for this
    // reason: https://vercel.com/guides/can-i-set-a-cookie-from-my-vercel-project-subdomain-to-vercel-app
    // Therefore we should make sure the "domain" param is set when the app is served
    // from the settle.co domain. Considering we need to share the token cookie across subdomains
    // to make the "redirect to brand" logic work, we stick to the corresponding env variable:
    if (process.env.REDIRECT_TO_BRAND) {
      o.domain = getCurrentDomain()
      o.path = '/'
    }

    setCookie(cookieKeys.token, token, o)
  }, [])
